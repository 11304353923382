import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import ChainList from "../components/chain-list"

export const Head = ({ location }) => <Seo pathname={location.pathname} />

const Home = ({ data }) => {
  return (
    <Layout>
      <h1>Current mainnet chains</h1>
      <ChainList
        chains={data.allChainsJson.edges}
        images={data.allFile.edges}
      />
    </Layout>
  )
}

export default Home

export const query = graphql`
  query {
    allChainsJson {
      edges {
        node {
          id
          about
          explorerUrl
          stakeUrl
          hidden
          disabled
          key
          logo
          name
          site
          services {
            tmVersion
            gitRepo
            binary
            root
            publicRpc
            publicGrpc
            publicRest
            seedNode
            chainId
            denom
            snapshot
            installation {
              genesisUrl
              addrbookUrl
              seeds
              installScript
            }
            stateSync {
              rpc
              peer
            }
            networkMap
          }
        }
      }
    }
    allFile {
      edges {
        node {
          id
          relativePath
          relativeDirectory
          publicURL
        }
      }
    }
  }
`
